import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"

import "./qanda.css";

import Photo from '../images/qanda/photo2.jpg'
import Icon from '../images/qanda/icon_pen.png'

const Data = [
  {
    q: '姓名判断などについて勉強したことがないのですが、大丈夫ですか？',
    a: [
      'はい、大丈夫です。今ある姓名判断とは違う方法で「名前」を解くメソッドなので初心者の方でも分かりやすい、すぐできる講座内容となっています。受講生の半数以上が、「名前」ついて学ぶのははじめてです。'
    ]
  },
  {
    q: 'なんとなく興味がある・・・程度でも、受講可能ですか？',
    a: [
      'はい、「名前から何がわかるの？」「なんとなく楽しそうだったから」「自分を好きになりたかったから」など、受講理由は人によってさまざまです。',
      '学びのため、仕事にしたいから、など明確な理由がないあなたも、お気軽にいらしてください。',
    ]
  },
  {
    q: 'どの順番で受講するのが最も理想的でしょうか？',
    a: [
      'はい、自分自身のことを知りたい方は、1DAYワークショップ「入門トレーニング」→「分析トレーニング」からの受講をおすすめします。',
      'おなまえメソッド®️を深く知りたい、家族や友人の名前についても分かるようになりたい、「おなまえメソッド®️」を活用して活動していきたい、という方は養成講座から受講することも出来ます。',
    ]
  },
  {
    q: '1DAYワークショップ、養成講座の違いはなんですか？',
    a: [
      'はい、1DAYワークショップは、「自分自身のことを知る」ことに特化した内容です。1日で「おなまえメソッド®️」を体験することを目的としています。こちらでは、その為の2つのトレーニングを用意していますが講座の開催ができる様になるというものではありません。',
      '\xa0',
      '◆「おなまえ®️入門トレーニング」は、名前を３ステップのワークで「名前」を活用して自分自身を知るを学びます。',
      '\xa0',
      '◆「おなまえ®️分析トレーニング」は、名前を３分類にして自分自身を分析していきます。',
      '\xa0',
      '◆ 養成講座は1DAYワークショップを開催できるコース',
      '有料セッションを開催できるコースをご用意しております。さらに知識を深め学び、実践的な知識もしっかりと学ぶカリキュラムですので受講後すぐに開催できます。',
    ]
  },
  {
    q: '「おなまえ®️公認講師」にはどうしたらなれますか？おなまえ®︎トレーニング、おなまえ®︎分析士は、それぞれ別の資格なのでしょうか？',
    a: [
      'はい、1DAYワークショップは、「自分自身のことを知る」ことに特化した内容です。1日で「おなまえメソッド®️」を体験することを目的としています。こちらでは、その為の2つのトレーニングを用意していますが講座の開催ができる様になるというものではありません。',
      '\xa0',
      '◆「おなまえ®️入門トレーニング」は、名前を３ステップのワークで「名前」を活用して自分自身を知るを学びます。',
      '\xa0',
      '◆「おなまえ®️分析トレーニング」は、名前を３分類にして自分自身を分析していきます。',
      '\xa0',
      '◆ 養成講座は1DAYワークショップを開催できるコース',
      '有料セッションを開催できるコースをご用意しております。さらに知識を深め学び、実践的な知識もしっかりと学ぶカリキュラムですので受講後すぐに開催できます。',
    ]
  },
  {
    q: '将来、「おなまえメソッド®️」でビジネスをしたいと思っているのですが可能でしょうか？',
    a: [
      'はい、充分に可能です。プロとして活動していけるだけの技術と、心構えをお伝えいたします。まずは実際に1DAYワークショップを体験していただいて、ご自身で体感して適性を判断していただくのも良いかと思います。今すでに活動している方はもちろんゼロの方にもビジネスとして活用している受講生の方達が大半です。',
    ]
  },
  {
    q: '養成講座それぞれ受講すると、どんなことが出来るようになりますか？',
    a: [
      '◆おなまえ®︎トレーナー',
      '・おなまえ®️入門トレーニング・おなまえ®️分析トレーニング内容を心理学や脳科学の引き寄せ法',
      '・宇宙の仕組み多方面からアプローチした診断について実践的に学び、深い知識を身につけることが出来ます。',
      '・個性の違いについて理解できるようになり、コミュニケーション能力が高まります。',
      '\xa0',
      '修了すると習ったその日から、1DAYワークショップ（入門トレーニング＆分析トレーニング）が出来るようになります。「おなまえ®︎トレーナー」の肩書を名乗ることが出来ます。',
      '\xa0',
      '◆おなまえ®️分析士',
      'おなまえ®️アナグラムをステージアップさせたおなまえ®️スーパーアナグラムを伝授いたします。メッセージを引き出す実践的な深い知識手法を学びます。',
    ]
  },
]

const renderDocuments = documents => {
  return documents.map((document, index) => {
    return (
      <p key={index}>
        {document}
      </p>
    )
  });
}

const render = () => {
  return Data.map((elem, index) => {
    return (
      <li className="dropdown" key={index}>
        <input type="checkbox" id={'q' + index} />
        <label htmlFor={'q' + index}>
          <div className="head">Q.</div>
          <div className="text">{elem.q}</div>
          <div className="plus">+</div>
        </label>
        <div className="answer">
          {renderDocuments(elem.a)}
        </div>
      </li>
    )
  });
}

const QandA = () => (
  <Layout>
    <SEO title="Q & A" />
    <div className="qanda">
      <div className="wrapper">
        <div className="content">
          <div className="title">
            <h1>Q&A</h1>
            <h2>ご質問について</h2>
            <p className="icon">
              <img alt=" " src={Icon} className="scale-to-fit" />
            </p>
          </div>
          <div className="photo">
            <img alt=" " src={Photo} className="scale-to-fit" />
          </div>
          <ul className="main">
            {render()}  
          </ul>
          <div className="btn-holder ff-min">
            <Link to='/service' className="special-link">
              <p>おなまえメソッド<span>&#174;</span></p>
              <p>サービス一覧はこちら</p>
            </Link>
          </div>
        </div>
      </div>
    </div>
  </Layout>
)

export default QandA 
